import * as React from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { OutboundLink } from "gatsby-plugin-google-analytics";

export interface Company {
	name: string;
	line1: string;
	postal_code: string;
	city: string;
	in: string;
	url: string;
	displayUrl?: string;
	phone: string;
	executive: string;
}

interface CompanyTileProps {
	company: Company;
}

export const CompanyTile: React.FC<CompanyTileProps> = ({ company }) => {
	return (
		<div className="p-8 space-y-3">
			<div className="font-bold text-xl">{company.name}</div>
			<div>
				<div>{company.line1}</div>
				<div>
					{company.postal_code} {company.city}
				</div>
			</div>
			<div>IČO: {company.in}</div>
			<div>
				<div>
					<OutboundLink
						href={company.url}
						target="_blank"
						className="text-blue-600 visited:text-purple-600 underline flex"
					>
						<ExternalLinkIcon className="w-4 h-4 mr-1 my-1" />
						{company.displayUrl || company.url}
					</OutboundLink>
				</div>
				<div>tel: {company.phone}</div>
			</div>
			<div>
				<div>{company.executive}</div>
				<div>jednatel</div>
			</div>
		</div>
	);
};
