import * as React from "react";
import { CompanyTile, Company } from "../components/CompanyTile";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const data: Record<string, Company> = {
  adea: {
    name: "ADEA projekt s.r.o.",
    line1: "Kafkova 1133/10",
    postal_code: "702 00",
    city: "Ostrava",
    in: "05725666",
    url: "https://www.adea-projekt.cz/cs/homepage/?utm_source=osa-projekt.cz&ref=osa-projekt.cz",
    displayUrl: "https://www.adea-projekt.cz",
    phone: "+420 595 693 200",
    executive: "Ing. arch. Aleš Vojtasík",
  },
  chvalek: {
    name: "CHVÁLEK ATELIÉR s.r.o.",
    line1: "Kafkova 1064/12",
    postal_code: "702 00",
    city: "Ostrava",
    in: "05725674",
    url: "https://www.chvalekatelier.cz",
    phone: "+420 595 693 250",
    executive: "Ing. arch. Martin Chválek, MBA",
  },
};

// markup
const IndexPage: React.FC = () => {
  return (
    <main className="text-gray-800 container min-h-screen mx-auto px-2 lg:px-16">
      <Helmet>
        <meta charSet="utf-8" />
        <title>OSA projekt s.r.o.</title>
      </Helmet>
      <div className="p-8">
        <StaticImage
          src="../images/logo-dark.png"
          alt="OSA projekt s.r.o."
          placeholder="blurred"
          layout="fixed"
          width={144}
          height={61}
        />
      </div>
      <div className="px-8">
        OSA projekt s.r.o. ke dni 31. 1. 2017 zanikla přeměnou na dvě nové
        společnosti:
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <CompanyTile company={data.adea} />
        <CompanyTile company={data.chvalek} />
      </div>
      <div className="px-8 py-8 text-gray-600 text-xs">
        {`COPYRIGHT © ${new Date().getFullYear()} OSA PROJEKT. VŠECHNA PRÁVA VYHRAZENA.`}
      </div>
    </main>
  );
};

export default IndexPage;
